export const SET_ADMIN = "SET_ADMIN";
export const UNSET_ADMIN = "UNSET_ADMIN";

export const SEND_EMAIL = "SEND_EMAIL";

export const UPDATE_PROFILE = "UPDATE_PROFILE";

export  const SIGNUP_ADMIN ="SIGNUP_ADMIN"; 

export const UPDATE_IMAGE_PROFILE = "UPDATE_IMAGE_PROFILE";

export const OPEN_ADMIN_DIALOG = "OPEN_ADMIN_DIALOG";

export const CLOSE_ADMIN_DIALOG = "CLOSE_ADMIN_DIALOG";
